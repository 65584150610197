<template>
  <div class="col-12 card card-body">
    <div class="col-12 table-responsive">
        <div class="col-12 text-center g" v-if="loading">
            <img src="/files/loading.svg" alt="">
        </div>
        <div class="g"><button @click="getArchive()" class="btn btn-sm btn-primary"><i class="fa fa-refresh"></i> تحديث</button></div>
        <table class="table table-sm table-hover table-bordered">
            <thead>
                <th>
                    المدرسة
                </th>
                <th>
                    الجوال
                </th>
                <th>
                    التاريخ
                </th>
                <th>
                    الحالة
                </th>
                <th>
                    طريقة الارسال
                </th>
                <th>
                    الرسالة
                </th>
            </thead>
            <tbody>
                <tr v-for="message in messages" :key="message._id">
                    <td>
                        <a href="javascript:;" @click="$router.push('/school/' + message.school_id)">{{ message.school_id }}</a>
                    </td>
                    <td>
                        {{ message.phone }}
                    </td>
                    <td>
                        {{ message.date }}
                    </td>
                    <td>
                        <span v-if="message.status == 0" class='btn btn-sm btn-warning'>
                            <i class="fa fa-clock-o"></i>
                            جاري الارسال
                        </span>
                        <span v-if="message.status == 1" class='btn btn-sm btn-success'>
                            <i class="fa fa-check"></i>
                            تم الارسال
                        </span>
                        <span v-if="message.status == 2" class='btn btn-sm btn-danger'>
                            <i class="fa fa-times"></i>
                            فشل الارسال
                        </span>
                        <span v-if="message.status == 3" class='btn btn-sm btn-info'>
                            <i class="fa fa-whatsapp"></i>
                            مرسل بواسطة اداة الواتساب
                        </span>
                        <small v-if="message.status == 2">
                            <br>
                            {{ message.response }}
                        </small>
                    </td>
                    <td>
                        <span v-if="message.method == 'sms'"><i class="fa fa-mobile"></i> SMS ({{ JSON.parse(message.settings).sms_portal }})</span>
                        <span v-if="message.method == 'whatsapp-api'"><i class="fa fa-whatsapp"></i> الواتس الذكي</span>
                        <span v-if="message.method == 'whatsapp'"><i class="fa fa-whatsapp"></i> اداة الواتساب</span>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-secondary" v-if="!message.view_message" @click="message.view_message = true;"><i class='fa fa-eye'></i> عرض الرسالة</button>
                        <textarea name="" class="form-control" id="" readonly v-model="message.message" cols="30" rows="10" v-if="message.view_message"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <button class="btn btn-danger" @click="page--; getArchive()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
        <button class="btn btn-primary" @click="page++; getArchive()" v-if="messages.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            messages: [],
            page: 0,
            loading: false,
        }
    },
    created(){
        this.getArchive();
    },
    methods: {
        getArchive(){
            var g = this;
            this.loading = true;
            $.post(api + '/admin/archive', {
                jwt: localStorage.getItem("jwt"),
                page: this.page
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.messages = JSON.parse(JSON.stringify(response.response)).map(function(a){
                        a.view_message = false
                        return a
                    })
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        }
    }
}
</script>

<style>

</style>